import { useEffect, useState } from "react";
import axios from "axios";
import Image from "react-bootstrap/Image";
import User from "../components/User";

function UserView({ userId }) {
    let [user, setUser] = useState(null);

    useEffect(() => {
        axios.get(`/users/${userId}`)
            .then((res) => {
                setUser(res.data);
            });
    }, [userId]);

    return (
        <div>
            {
                !user ?
                    <div>Loading User...</div>
                    :
                    <User user={user}></User>
            }
        </div>


    );
}

export default UserView;