import Image from "react-bootstrap/Image";

function User({ user }) {
    return (
        <div>
            <Image src={user.avatarUrl}></Image>
            <div>{`${user.firstName} ${user.lastName}`}</div>
            <small>{user.location}</small>
        </div>
    );
};

export default User;