import './App.css';
import TeamsView from "./views/TeamsView";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Container>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/teams"></Redirect>
              </Route>
              <Route path="/teams">
                <TeamsView></TeamsView>
              </Route>
            </Switch>
          </Container>
        </header>
      </div>
    </Router>
  );
}

export default App;
