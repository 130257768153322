import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import UserView from "./UserView";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TeamView() {
    let { teamId } = useParams();
    let [team, setTeam] = useState(null);

    useEffect(() => {
        axios.get(`/teams/${teamId}`)
            .then((res) => {
                setTeam(res.data);
            });
    }, [teamId]);

    return (
        <div>
            {
                !team ?
                    "Loading Team Information..."
                    :
                    <div>
                        <div><strong>{team.name}</strong></div>
                        <div>
                            <div><u>Team Lead</u></div>
                            <UserView userId={team.teamLeadId}></UserView>
                        </div>
                        <div>
                            <div><u>Team Members</u></div>
                            <Row>
                                {team.teamMemberIds.map((teamMemberId, index) => {
                                    return (
                                        <Col key={index}>
                                            <UserView userId={teamMemberId}></UserView>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </div>
            }
        </div>
    )
}

export default TeamView;