import { useEffect, useState } from "react";
import axios from "axios";
import TeamView from "./TeamView";
import { BrowserRouter as Router, Route, Switch, Link, useRouteMatch } from "react-router-dom";
import SearchField from "../components/SearchField";

function TeamsView() {
    let { path, url } = useRouteMatch();
    let [loading, setLoading] = useState(true);
    let [teams, setTeams] = useState([]);
    let [searchText, setSearchText] = useState("");

    useEffect(() => {
        axios.get("/teams")
            .then((res) => {
                setLoading(false);

                let fetchedTeams = res.data;
                fetchedTeams.sort((a,b) => {
                    return a.name < b.name ? -1 : 1;
                });
                setTeams(fetchedTeams);
            });
    }, []);


    let handleSearchTextChange = (newText) => {
        setSearchText(newText.toLowerCase());
    }

    return (
        <Router>
            <Switch>
                <Route path={path} exact>
                    <div>Teams</div>
                    <div>
                        <span>Search Team </span>
                        <SearchField onTextChanged={handleSearchTextChange} initialText={searchText}></SearchField>
                    </div>
                    {
                        loading ?
                            "Loading Teams..."
                            :
                            <ul>
                                {teams.map((team, index) => {
                                    if (searchText === "" || team.name.toLowerCase().includes(searchText)) {
                                        return (
                                            <li key={index}>
                                                <Link to={`${url}/${team.id}`}>{team.name}</Link>
                                            </li>

                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </ul>
                    }
                </Route>
                <Route path={`${path}/:teamId`}>
                    <TeamView></TeamView>
                </Route>
            </Switch>

        </Router>
    );
}

export default TeamsView;